const getters = {
  userInfo: state => state.userInfo,
  language: state => 'zh',
  // 登录模块
  showLogin: state => state.showLogin,
  activeTab: state => state.activeTab,
  // 购物车数量
  shopNum: state => state.shopNum,
  hidden: state => {
    // const temp = state.current
    if (typeof document.hasFocus !== 'function') {
      return document.hidden
    }
    return !document.hasFocus()
  },
  // 通知数量
  noticeNum: state => state.noticeNum
}
export default getters
