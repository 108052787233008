<template>
  <div class="nav-bar">
    <div class="center-content">
      <div class="menu">
        <div class="menu-ul">
          <template v-for="(item, index) in routeList">
            <!-- 单行 -->
            <div class="menu-item" v-if="!item.hidden" @click="handleSelect(item)" :index="index" :class="(item.path === activePath || (item.path === '/' && activePath === '/home')) ? 'is-active' : ''">{{item.meta.title}}</div>
            <!-- 多行 -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
export default {
  name: 'Navbar',
  data() {
    return {
      routeList: [],
      activePath: '/'
    }
  },
  watch: {
    $route(to, form) {
      this.handleSetRoute()
    }
  },
  mounted() {
    this.handleSetRoute()
  },
  methods: {
    handleSelect(item) {
      if (item.path === '/personal') {
        if (getToken()) {
          this.$router.push({
            path: item.path
          })
        } else {
          this.$store.dispatch('setActiveTab', 'login')
          this.$store.dispatch('setShowLogin', true)
        }
      } else {
        this.$router.push({
          path: item.path
        })
      }
    },
    handleSetRoute() {
      const _hash = window.location.hash.split('#')[1].split('?')[0]
      const _arr = this.$router.options.routes
      const _hideArr = ['/train/index', '/train/detail', '/train/course', '/train/courseDetail', '/personal/train', '/trainShoppingCart/payOrder','/welcome/home','/welcome','/authorize','/learnCentre','/setFlag','/goLogin','/loginRedirect/home']
      if (_hideArr.indexOf(_hash) > -1) {
        for (const item of _arr) {
          if (item.name === 'train' || (item.name === 'personal')) {
            item.hidden = false
          } else {
            item.hidden = true
          }
        }
      } else {
        for (const item of _arr) {
          if (item.name === 'personal' || item.name === 'shoppingCart' || item.name === 'task' || item.name === 'feedback' || item.name === 'exam' || item.name === 'search' || item.name === 'trainShoppingCart' || item.name === 'welcome' || item.name === 'authorize'|| item.name === 'learnCentre' || item.name === 'setFlag'|| item.name === 'goLogin'|| item.name === 'loginRedirect' || item.name === 'lndxCourse') {
            item.hidden = true
          } else {
            item.hidden = false
          }
        }
      }
      this.routeList = _arr
      for (const item of this.routeList) {
        const _path = item.path === '/' ? '/home' : item.path
        if (_hash.indexOf(_path) > -1) {
          this.activePath = _path
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar{
  width: 100%;
  height: 82px;
  background-color: #3B7219;
  .center-content{
    width: 1400px;
    height: 82px;
    margin: 0 auto;
    background-color: #3B7219;
    .menu{
      .menu-ul{
        .menu-item{
          display: inline-block;
          padding: 0;
          margin-right: 16px;
          height: 74px;
          line-height: 74px;
          margin-top: 4px;
          font-size: 30px;
          color: #FFFFFF;
          position: relative;
          background-color: #3B7219;
          cursor: pointer;
          &:last-child{
            margin-right: 0;
          }
          &:hover{
            color: #FFE073;
            background-color: #3B7219;
          }
          &.is-active{
            font-weight: 500;
            border: none;
            color: #FFE073;
            border-bottom: 7px solid #FFE073;
          }
        }
      }
    }
  }
}
</style>
