import promise from 'es6-promise'
import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import SparkMD5 from 'spark-md5'

promise.polyfill()

// 限制快速点击
var requesting = []
var limitTime = 500

// 创建axios实例
const service = axios.create({
  baseURL: window.wtConst.BASE_API, // api的base_url 本地跨域配置注释
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['x-access-token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  config.headers['x-device-type'] = 1
  config.headers['x-device-id'] = ''
  // 限制快速点击 start
  var requestingId = config.data ? SparkMD5.hash(JSON.stringify(config.data)) : 'null'
  if (config.method === 'post') {
    const nowTime = new Date().getTime()
    requesting = requesting.filter(item => {
      return item.startTime + limitTime > nowTime
    })
    const sessionUrl = requesting.filter(item => {
      return item.requestingId === requestingId
    })
    if (sessionUrl.length > 0) {
      console.log(requestingId + '请求重复 中断请求!', config.data)
      return
    }
    const item = {
      requestingId: requestingId,
      startTime: nowTime
    }
    requesting.push(item)
  }
  // 限制快速点击 end
  return config
}, error => {
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === undefined) {
      return res
    }
    // 登录过期
    if (res.code === '2000' || res.code === '2001') {
      console.log('登录过期')
      store.dispatch('logOut')
      // return
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(res)
    }

    if (res.code !== '0') {
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    // console.log(error)// for debug
    const _error = error.toString()
    if (_error.indexOf('TypeError') === -1) {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  }
)

export default service
