const filters = {
  FILTER_Gender: {
    '1': '男',
    '2': '女',
    // '3': '保密',
    'default': '-'
  },
  FILTER_EduLevel: {
    '1': '小学',
    '2': '初中',
    '3': '高中',
    '4': '大专',
    '5': '大学本科',
    '6': '研究生',
    'default': '-'
  },
  FILTER_CourseType: {
    '1': '免费课程',
    '2': '精品课程',
    '3': '直播课程'
  },
  FILTER_CourseStatus: {
    '0': '-',
    '1': '未开始',
    '2': '进行中',
    '3': '已完成',
    'default': '-'
  },
  FILTER_CourseStudyStatus: {
    '0': '-',
    '1': '未开始',
    '2': '进行中',
    '3': '已完成',
    'default': '-'
  },
  // 未确定参数
  FILTER_TaskStatus: {
    '1': '优',
    '2': '良',
    '3': '不合格',
    '4': '待提交',
    '5': '待批阅',
    'default': '-'
  },
  FILTER_TaskType: {
    '2': '正在进行',
    '1': '即将开始',
    '3': '已结束',
    'default': '-'
  },
  FILTER_ExamType: {
    '1': '待答题',
    '2': '待批阅',
    '3': '合格',
    '4': '不合格',
    'default': '-'
  },
  FILTER_LiveType: {
    '1': '正在进行',
    '2': '即将开始',
    '3': '已完成',
    'default': '-'
  },
  FILTER_CreditType: {
    '0': '未获得',
    '1': '已获得'
  },
  FILTER_GoodsType: {
    '1': '免费课程',
    '2': '精品课程',
    '3': '直播课程',
    '4': '省校报名',
    'default': '-'
  },
  FILTER_OrderType: {
    '1': '已付款',
    '2': '未付款',
    'default': '-'
  },
  FILTER_PayType: {
    '1': '微信',
    '2': '支付宝',
    'default': '-'
  }
}
export default filters
