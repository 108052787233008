<template>
  <div class="login-component">
    <div class="login-component-shadow" @click="handleCloseLogin"></div>
    <!-- 登录/注册 -->
    <div class="login-component-content" v-if="(activeTabPage === 'login' || activeTabPage === 'register') && !registerSubmit">
      <div class="con-tab">
        <div class="tab-item hover-opa" :class="activeTabPage === 'login' ? 'tab-item-active' : ''" @click="handleChangeTab('activeTabPage', 'login')">用户登录</div>
        <div class="tab-item hover-opa" :class="activeTabPage === 'register' ? 'tab-item-active' : ''" @click="handleChangeTab('activeTabPage', 'register')">用户注册</div>
      </div>
      <div class="con-panel">
        <div class="panel-tab" v-if="activeTabPage === 'login'">
          <div class="tab-item hover-opa" :class="loginType === 'wechat' ? 'tab-item-active' : ''"  @click="handleChangeTab('loginType', 'wechat')">微信登录</div>
          <div class="tab-item hover-opa" :class="loginType === 'account' ? 'tab-item-active' : ''" @click="handleChangeTab('loginType', 'account')">账号登录</div>
          <div class="tab-item hover-opa" :class="loginType === 'tel' ? 'tab-item-active' : ''"  @click="handleChangeTab('loginType', 'tel')">手机号登录</div>
        </div>
        <!-- 账号登录 -->
        <div class="panel-form" v-if="activeTabPage === 'login' && loginType === 'account'">
          <div class="form-item">
            <img src="../../assets/images/account@2x.png" class="item-icon" alt="">
            <input class="item-input" type="text" v-model="account.idCard" maxlength="30" placeholder="请输入身份证号">
          </div>
          <div class="form-item">
            <img src="../../assets/images/password@2x.png" class="item-icon item-icon-pwd" alt="">
            <input class="item-input" type="password" v-model="account.password" maxlength="20" placeholder="请输入密码(默认密码为身份证后6位)">
          </div>

          <div class="form-item-btn">
            <el-checkbox v-model="account.rememberPwd">自动登录</el-checkbox>
            <div class="btn-text hover-opa" @click="activeTabPage = 'forget'">忘记密码?</div>
          </div>
          <div class="form-item-btn other-login">
          <div class="other-login-title">其他登录方式</div>
          <div class="other-login-img">
            <img @click="goLndx" src="../../assets/images/lndx.png" width="120"><br>
            国家老年大学
          </div>
        </div>

        </div>
        <!-- 手机号登录 -->
        <div class="panel-form" v-if="activeTabPage === 'login' && loginType === 'tel'">
          <div class="form-item">
            <img src="../../assets/images/tel.png" class="item-icon item-icon-tel" alt="">
            <input class="item-input" type="text" v-model="tel.mobile" placeholder="请输入手机号" maxlength="11">
          </div>
          <div class="form-item form-item-code">
            <div class="item-code">
              <img src="../../assets/images/code.png" class="item-icon item-icon-code" alt="">
              <input class="item-input" type="text" v-model="tel.code" placeholder="请输入验证码">
            </div>
            <div class="item-btn hover-opa" :class="codeText === '发送验证码' ? '' : 'item-btn-dis'" @click="handleGetCode">{{codeText === '发送验证码' ? codeText : codeText + 's'}}</div>
          </div>
        </div>
        <!-- 微信登录 -->
        <div class="panel-form" v-if="activeTabPage === 'login' && loginType === 'wechat'">
          <div class="form-wechat">
            <div class="wechat-icon" id="wechat_login_container"></div>
            <div class="wechat-text">微信扫码登录</div>
          </div>
        </div>
        <!-- 注册 -->
        <div class="panel-form-register" v-if="activeTabPage === 'register'">
          <el-form :model="register" ref="model" label-width="190px">
            <el-form-item label="姓名:" prop="realname">
              <el-input v-model="register.realname" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别:" prop="collUserSex">
              <el-select v-model="register.collUserSex" placeholder="请选择性别">
                <el-option v-for="(item, index) in sexList" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份证号:" prop="idCard">
              <el-input v-model="register.idCard" placeholder="请输入身份证号"></el-input>
            </el-form-item>
            <el-form-item label="手机号:" prop="mobile">
              <el-input v-model="register.mobile" placeholder="请输入手机号" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item label="学历层次:" prop="eduLevel">
              <el-select v-model="register.eduLevel" placeholder="请选择学历层次">
                <el-option v-for="(item, index) in educationList" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地区:" prop="areas">
              <el-cascader v-model="register.areas" :props="cityProps" :options="cityOptions" placeholder="请选择地区" @change="handleChangeAreas"></el-cascader>
            </el-form-item>
            <el-form-item label="学习中心:" prop="learningCentre">
              <el-select v-model="register.learningCentre" placeholder="请选择学习中心">
                <el-option v-for="(item, index) in learningCentreList" :key="index" :label="item.name" :value="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!--<div class="regNotice">
            注册到吉林老年学习在线的同时也会帮您注册国家老年大学平台账号，享受双学籍
          </div>-->
        </div>
        <div class="panel-btn" v-if="(activeTabPage === 'login' && (loginType === 'account' || loginType === 'tel')) || (activeTabPage === 'register')">
          <div class="btn-item hover-opa btn-item-close" @click="$emit('close')">取消</div>
          <div class="btn-item hover-opa" v-if="activeTabPage === 'register'" @click="handleRegister">确定</div>
          <div class="btn-item hover-opa" v-if="activeTabPage === 'login'" @click="handleLogin">登录</div>
        </div>
      </div>
    </div>
    <!-- 注册提交 -->
    <div class="register-component-content" v-if="activeTabPage === 'register' && registerSubmit">
      <div class="panel-tip">请您仔细核对您的个人信息，确认无误后请提交。</div>
      <div class="panel-form">
        <div class="form-item">
          <div class="item-label item-label-tip">姓名：</div>
          <div class="item-value">{{register.realname}}</div>
        </div>
        <div class="form-item">
          <div class="item-label">性别：</div>
          <div class="item-value">{{register.collUserSex|FILTER_Gender}}</div>
        </div>
        <div class="form-item">
          <div class="item-label item-label-tip">身份证号：</div>
          <div class="item-value">{{register.idCard}}</div>
        </div>
        <div class="form-item">
          <div class="item-label">手机号：</div>
          <div class="item-value">{{register.mobile}}</div>
        </div>
        <div class="form-item">
          <div class="item-label">学历层次：</div>
          <div class="item-value">{{register.eduLevel|FILTER_EduLevel}}</div>
        </div>
        <div class="form-item">
          <div class="item-label">地区：</div>
          <div class="item-value">{{register.province + '-' + register.area + '-' + register.city}}</div>
        </div>
        <div class="form-item">
          <div class="item-label">学习中心：</div>
          <div class="item-value">{{register.learningCentre}}</div>
        </div>

      </div>
      <div class="panel-btn">
        <div class="btn-item hover-opa btn-item-close" @click="handleRegisterBack">返回修改</div>
        <div class="btn-item hover-opa" @click="handleRegisterSubmit">确定并注册</div>
      </div>
    </div>
    <!-- 忘记密码 -->
    <div class="forget-component-content" v-if="activeTabPage === 'forget'">
      <div class="panel-tab">找回密码</div>
      <div class="panel-form">
        <div class="form-item">
          <div class="item-title">手机号:</div>
          <input type="text" class="item-input" v-model="forget.mobile" placeholder="请输入手机号" maxlength="11">
        </div>
        <div class="form-item">
          <div class="item-title">验证码:</div>
          <input type="text" class="item-input-other" v-model="forget.smsCode" placeholder="请输入验证码">
          <div class="item-code hover-opa" :class="forgetCodeText === '发送验证码' ? '' : 'item-code-dis'" @click="handleGetForgetCode">{{forgetCodeText === '发送验证码' ? '发送验证码' : forgetCodeText + 's'}}</div>
        </div>
        <div class="form-item">
          <div class="item-title">新密码:</div>
          <input type="password" class="item-input" v-model="forget.newPassword" placeholder="请输入新密码">
        </div>
        <div class="form-item">
          <div class="item-title">确认密码:</div>
          <input type="password" class="item-input" v-model="forget.confirmPassword" placeholder="请确认新密码">
        </div>
      </div>
      <div class="panel-btn">
        <div class="btn-item hover-opa btn-item-close" @click="activeTabPage = 'login'">返回登录</div>
        <div class="btn-item hover-opa" @click="handleForget">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { setToken, getStorgeItem, setStorgeItem } from '@/utils/auth'
export default {
  name: 'LoginComponent',
  props: ['activeTab'],
  data() {
    return {
      activeTabPage: 'login', // login register forget
      loginType: 'account', // account tel wechat
      registerSubmit: false, // true false
      // 账号登录
      account: {
        password: '',
        idCard: '',
        collId: '1', // 高校id
        rememberPwd: false
      },
      // 手机号登录
      tel: {
        mobile: '',
        code: '',
        collId: '1',
        openid: ''
      },
      codeText: '发送验证码',
      codeTimer: null,
      // 用户注册
      sexList: [
        { label: '男', value: 1 },
        { label: '女', value: 2 }
        // { label: '保密', value: 3 }
      ],
      educationList: [
        { label: '小学', value: 1 },
        { label: '初中', value: 2 },
        { label: '高中', value: 3 },
        { label: '大专', value: 4 },
        { label: '大学本科', value: 5 },
        { label: '研究生', value: 6 }
      ],
      cityOptions: [],
      cityProps: {
        value: 'name',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover'
      },
      learningCentreList: [],
      register: {
        realname: '',
        collUserSex: '',
        idCard: '',
        mobile: '',
        eduLevel: '',
        areas: [],
        province: '', // 省份
        city: '', // 市
        area: '', // 区
        learningCentre: '', // 学习中心
        username: '',
        collId: '1' // 高校id
      },
      // 找回密码
      forget: {
        collId: '1',
        mobile: '',
        smsCode: '',
        newPassword: '',
        confirmPassword: ''
      },
      forgetCodeText: '发送验证码',
      forgetTimer: null,
      registerLoading: null
    }
  },
  mounted() {
    this.activeTabPage = this.activeTab
    if (this.activeTabPage === 'login') {
      this.account = getStorgeItem('openUniversityAccount') || { idCard: '', password: '', collId: '1', rememberPwd: false }
    }
    if (this.activeTabPage === 'register') {
      this.handleGetCity()
    }
  },
  methods: {
    handleChangeTab(key, value) {
      this[key] = value
      // 注册
      if (key === 'activeTabPage' && value === 'register') {
        this.handleGetCity()
      }
      // 微信登录
      if (key === 'loginType' && value === 'wechat') {
        this.$nextTick(() => {
          const redirectUri = encodeURIComponent(window.location.href.split('#')[0])
          // const redirectUri = encodeURIComponent('https://aged-edu.dev1.wangtiansoft.com/web/') // 测试
          const wechatObj = new WxLogin({
            self_redirect: false,
            id: 'wechat_login_container',
            appid: window.wtConst.wxAppId,
            scope: 'snsapi_login',
            redirect_uri: redirectUri,
            state: '',
            style: 'black',
            href: ''
          })
        })
      }
    },
    // 关闭面板
    handleCloseLogin() {
      this.$emit('close')
    },
    // 获取注册城市及学习中心备选列表
    handleGetCity() {
      const params = {
        area: '',
        city: '',
        id: 0,
        name: '',
        province: '',
        type: ''
      }
      this.$api.login.centerList(params).then(res => {
        if (res.data) {
          for (const item of res.data) {
            item.name = item.name
            item.child = item.childList
            for (const item1 of item.childList) {
              item1.name = item1.name
              item1.child = item1.childList
              for (const item2 of item1.childList) {
                item2.name = item2.name
              }
            }
          }
          this.cityOptions = res.data
        }
      })
    },
    // 获取验证码
    handleGetCode() {
      const countFunc = (t) => {
        if (t === 0) {
          this.codeText = '发送验证码'
          clearTimeout(this.codeTimer)
          this.codeTimer = null
        } else {
          this.codeText = t
          t--
          this.codeTimer = setTimeout(() => {
            countFunc(t)
          }, 1000)
        }
      }
      if (this.codeText === '发送验证码') {
        if (!this.tel.mobile || this.tel.mobile.trim() === '') {
          return this.$message.warning('请输入手机号')
        }
        if (!this.$util.telReg(this.tel.mobile)) {
          return this.$message.warning('请输入正确的手机号')
        }
        const params = {
          mobile: this.tel.mobile,
          collId: '1'
        }
        this.$api.login.sendLoginSmsCode(params).then(res => {
          this.$message.success('验证码发送成功')
          countFunc(60)
        })
      } else {
        return this.$message.warning('请勿重复获取')
      }
    },
    // 登录
    handleLogin() {
      // 账号登录
      if (this.loginType === 'account') {
        const params = Object.assign(this.account, {})
        if (!params.idCard || params.idCard.trim() === '') {
          return this.$message.warning('请输入身份证号')
        }
        if (!this.$util.idCardReg(params.idCard)) {
          return this.$message.warning('请输入正确的身份证号')
        }
        if (!params.password || params.password.trim() === '') {
          return this.$message.warning('请输入密码')
        }
        params.idCard = params.idCard.toLocaleLowerCase()
        this.$api.login.loginByPassword(params).then(res => {
          // 记住密码
          if (params.rememberPwd) {
            setStorgeItem('openUniversityAccount', params)
          } else {
            setStorgeItem('openUniversityAccount', null)
          }
          // 存储IM登录信息
          setStorgeItem('openUniversityIM', { userID: res.data.loginUser.accountNo, userSig: res.data.usersig })
          // 登录成功储存登录信息
          setToken(res.data.token)
          this.$store.dispatch('getUserInfo')
          this.$store.dispatch('setShopNum')
          this.$store.dispatch('setNoticeNum')
          // 是否首次登录
          if (res.data.loginUser && res.data.loginUser.loginNum * 1 === 1) {
            this.$emit('close', 1)
          } else {
            this.$emit('close')
            this.$message.success('登录成功')
          }
        })
      }
      // 手机号登录
      if (this.loginType === 'tel') {
        if (!this.tel.mobile || this.tel.mobile.trim() === '') {
          return this.$message.warning('请输入手机号')
        }
        if (!this.$util.telReg(this.tel.mobile)) {
          return this.$message.warning('请输入正确的手机号')
        }
        if (!this.tel.code || this.tel.code.trim() === '') {
          return this.$message.warning('请输入验证码')
        }
        this.$api.login.loginByCode(this.tel).then(res => {
          // 存储IM登录信息
          setStorgeItem('openUniversityIM', { userID: res.data.loginUser.accountNo, userSig: res.data.usersig })
          // 登录成功储存登录信息
          setToken(res.data.token)
          this.$store.dispatch('getUserInfo')
          this.$store.dispatch('setShopNum')
          this.$store.dispatch('setNoticeNum')
          // 是否首次登录
          if (res.data.loginUser && res.data.loginUser.loginNum * 1 === 1) {
            this.$emit('close', 1)
          } else {
            this.$emit('close')
            this.$message.success('登录成功')
          }
        })
      }
    },
    // 选择地区
    handleChangeAreas(e) {
      if (e && e.length > 0) {
        this.register.province = e[0]
        this.register.area = e[1]
        this.register.city = e[2]
        // 生成学习中心
        for (const item of this.cityOptions) {
          if (item.name === e[0]) {
            for (const item1 of item.childList) {
              if (item1.name === e[1]) {
                for (const item2 of item1.childList) {
                  if (item2.name === e[2]) {
                    this.learningCentreList = item2.childList
                    this.register.learningCentre = ''
                  }
                }
              }
            }
          }
        }
      }
    },
    // 注册提交校验
    handleRegister() {
      const params = Object.assign(this.register, {})
      if (!params.realname || params.realname.trim() === '') {
        return this.$message.warning('请输入姓名')
      }
      if (params.collUserSex.toString().length === 0) {
        return this.$message.warning('请选择性别')
      }
      if (!params.idCard || params.idCard.trim() === '') {
        return this.$message.warning('请输入身份证号')
      }
      if (!this.$util.idCardReg(params.idCard)) {
        return this.$message.warning('请输入正确的身份证号')
      }
      if (!params.mobile || params.mobile.trim() === '') {
        return this.$message.warning('请输入手机号')
      }
      if (!this.$util.telReg(params.mobile)) {
        return this.$message.warning('请输入正确的手机号')
      }
      if (params.eduLevel.toString().length === 0) {
        return this.$message.warning('请选择学历层次')
      }
      if (params.areas.length === 0) {
        return this.$message.warning('请选择所在地区')
      }
      if (!params.learningCentre) {
        return this.$message.warning('请选择学习中心')
      }
      this.registerSubmit = true
    },
    // 注册返回修改
    handleRegisterBack() {
      this.registerSubmit = false
    },
    // 注册提交
    handleRegisterSubmit() {
      const params = Object.assign(this.register, {})
      params.idCard = params.idCard.toLocaleLowerCase()
      this.registerLoading = this.$loading({
        lock: true,
        text: '注册中...'
      })
      this.$api.login.regist(params).then(res => {
        this.registerLoading.close()
        this.$emit('close', 3)
      }).catch(err => {
        this.registerLoading.close()
      })
    },
    // 忘记密码
    handleGetForgetCode() {
      const countFunc = (t) => {
        if (t === 0) {
          this.forgetCodeText = '发送验证码'
          clearTimeout(this.forgetTimer)
          this.forgetTimer = null
        } else {
          this.forgetCodeText = t
          t--
          this.forgetTimer = setTimeout(() => {
            countFunc(t)
          }, 1000)
        }
      }
      if (this.forgetCodeText === '发送验证码') {
        if (!this.forget.mobile || this.forget.mobile.trim() === '') {
          return this.$message.warning('请输入手机号')
        }
        if (!this.$util.telReg(this.forget.mobile)) {
          return this.$message.warning('请输入正确的手机号')
        }
        const params = {
          mobile: this.forget.mobile,
          collId: '1'
        }
        this.$api.login.sendResetPasswordSmsCodeByMobile(params).then(res => {
          this.$message.success('验证码发送成功')
          countFunc(60)
        })
      } else {
        return this.$message.warning('请勿重复获取')
      }
    },
    handleForget() {
      const params = Object.assign(this.forget, {collId: 1})
      if (!params.mobile || params.mobile.trim() === '') {
        return this.$message.waring('请输入手机号')
      }
      if (!this.$util.telReg(params.mobile)) {
        return this.$message.warning('请输入正确的手机号')
      }
      if (!params.smsCode || params.smsCode.trim() === '') {
        return this.$message.warning('请输入验证码')
      }
      if (!params.newPassword || params.newPassword.trim() === '') {
        return this.$message.warning('请输入新密码')
      }
      if (!params.confirmPassword || params.confirmPassword.trim() === '') {
        return this.$message.warning('请输入确认密码')
      }
      if (params.newPassword !== params.confirmPassword) {
        return this.$message.warning('两次输入的密码不一致')
      }
      this.$api.login.resetPasswordByMobile(params).then(res => {
        this.$message.success('提交成功，请重新登录')
        this.activeTabPage = 'login'
      })
    },
    goLndx() {

      this.$api.auth.getAuthParams().then(res => {
        let url = res.data.loginUrl
        let responseType = res.data.responseType
        let clientId = res.data.clientId
        let redirectUri = encodeURI(res.data.redirectUri)
        let scope = res.data.scope
        let state = res.data.state
        window.location = url+'?response_type='+responseType+'&client_id='+clientId+'&redirect_uri='+redirectUri+'&scope='+scope+'&state='+state;

      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/loginComponent.scss';
  .regNotice{
    text-align: center;
    color: #ff0000;
  }
  .other-login {
    flex-direction: column;
    margin-top: 0px!important;
    margin-bottom: 10px;
  }
  .other-login-title {
    width: 80%;
    font-size:30px;
    background-image: url('../../assets/images/login_thin.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    text-align: center;
    color: #666666;
  }
  .other-login-img {
    font-size: 26px;
    text-align: center;
    color: #666666;
  }
  .other-login-img img {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }
</style>
