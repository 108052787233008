<template>
  <div class="msg-box-component">
    <div class="msg-box-component-shadow"></div>
    <div class="msg-box-component-content" :class="activeTabPage === 'registerSuc' ? 'msg-box-register-component-content' : ''">
      <div class="el-icon-close hover-opa" @click="$emit('close')"></div>
      <!-- 首次登录 -->
      <div v-if="activeTabPage === 'firstTimeLogin'" class="first-time-login">
        <div class="head">欢迎您首次登录！</div>
        <div class="select-text">请勾选您感兴趣的专业：</div>
        <div class="select-ul">
          <div class="select-li hover-opa" v-for="(item, index) in majorArr" :key="index" :class="item.select ? 'select-li-active' : ''" @click="handleSelectMajor(index)">{{item.hobbyName}}</div>
        </div>
        <div class="btns">
          <div class="btn-item btn-item-close hover-opa" @click="$emit('close')">取消</div>
          <div class="btn-item hover-opa" @click="handleSaveMajor">确定</div>
        </div>
      </div>
      <!-- 注册成功 -->
      <div v-if="activeTabPage === 'registerSuc'" class="register-suc">
        <div class="head">注册成功！</div>
        <div class="tip-text">初始密码为身份证后6位</div>
        <div class="tip-text">如果要修改密码请前往个人空间</div>
        <div class="tip-text-other tip-text-other-top">个人信息如需修改</div>
        <div class="tip-text-other tip-text-other-bottom">请联系所在学习中心管理人员！</div>
      </div>
      <!-- 支付成功 -->
      <div v-if="activeTabPage === 'paySuc'" class="pay-suc">
        <div class="head">支付已成功！</div>
        <div class="tip-text">支付已成功，请您记得准时收看课程！</div>
        <div class="btns">
          <div class="btn-item btn-item-close hover-opa" @click="handleBackHome">返回首页</div>
          <!-- <div class="btn-item hover-opa" @click="handleOrder">查看订单</div> -->
          <div v-if="source * 1 === 1 || source * 1 === 3" class="btn-item hover-opa" @click="handleLinkToPersonalCourse">查看课程</div>
          <div v-if="source * 1 === 2" class="btn-item hover-opa" @click="handleLinkToPersonalTrain">查看培训</div>
        </div>
      </div>
      <!-- 支付失败 -->
      <div v-if="activeTabPage === 'payEro'" class="pay-ero">
        <div class="head">抱歉！您的支付遇到问题</div>
        <div class="btns">
          <div class="btn-item btn-item-close hover-opa" @click="handleBackHome">返回首页</div>
          <!-- <div class="btn-item hover-opa">查看订单</div> -->
          <div v-if="source * 1 === 1 || source * 1 === 3" class="btn-item hover-opa" @click="handleLinkToPersonalCourse">查看课程</div>
          <div v-if="source * 1 === 2" class="btn-item hover-opa" @click="handleLinkToPersonalTrain">查看培训</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setStorgeItem } from '../../utils/auth.js'
export default {
  name: 'MsgBoxComponent',
  props: ['activeTab', 'source'],
  data() {
    return {
      activeTabPage: 'firstTimeLogin', // firstTimeLogin registerSuc paySuc payEro
      majorArr: []
    }
  },
  mounted() {
    this.activeTabPage = this.activeTab
    if (this.activeTabPage === 'firstTimeLogin') {
      this.handleGetMajor()
    }
  },
  methods: {
    handleSelectMajor(i) {
      const item = this.majorArr[i]
      this.$set(item, 'select', !item.select)
    },
    handleBackHome() {
      this.$emit('close')
      this.$router.push({ path: '/home' })
    },
    handleOrder() {
      this.$message.warning('查看订单开发中')
      // this.$emit('close')
    },
    handleLinkToPersonalCourse() {
      setStorgeItem('personalTab', 'course')
      this.$router.push({
        path: '/personal/index'
      })
    },
    handleLinkToPersonalTrain() {
      setStorgeItem('trainTab', 'train')
      this.$router.push({
        path: '/personal/train'
      })
    },
    // 获取专业列表
    handleGetMajor() {
      const params = {
        formData: {
          createTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' }
      }
      this.$api.login.hobbyList(params).then(res => {
        if (res.data.list) {
          this.majorArr = res.data.list
        }
      })
    },
    handleSaveMajor() {
      const arr = []
      for (const item of this.majorArr) {
        if (item.select) {
          arr.push(item)
        }
      }
      if (arr.length === 0) {
        return this.$message.warning('请至少选择一个专业')
      }
      if (arr.length > 3) {
        return this.$message.warning('最多选择3个专业')
      }
      // 保存
      this.$api.login.hobbySave({ hobbys: arr }).then(res => {
        this.$message.success('保存成功')
        this.$emit('close')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/msgBoxComponent.scss';
</style>
