import Vue from 'vue'
import Router from 'vue-router'
import util from '../utils/util.js'
import { setStorgeItem } from '../utils/auth.js'
/* Layout */
import Layout from '../modules/layout/Layout'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/
const dynamicRouters = [
  // 首页
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'home',
    meta: {
      title: '首页'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'home',
        component: () => import('@/modules/home/index')
      }
    ]
  },
  // 学校简介
  {
    path: '/schoolProfile',
    component: Layout,
    redirect: '/schoolProfile/index',
    name: 'schoolProfile',
    meta: {
      title: '学校简介'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/schoolProfile/index')
      }
    ]
  },
  // 新闻资讯
  {
    path: '/news',
    component: Layout,
    redirect: '/news/index',
    name: 'news',
    meta: {
      title: '新闻资讯'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/news/index')
      },
      {
        path: 'detail',
        component: () => import('@/modules/news/detail')
      }
    ]
  },
  // 资源中心
  {
    path: '/resource',
    component: Layout,
    redirect: '/resource/index',
    name: 'resource',
    meta: {
      title: '资源中心'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/resource/index')
      },
      {
        path: 'detail',
        component: () => import('@/modules/resource/detail')
      },
      {
        path: 'list',
        component: () => import('@/modules/resource/list')
      },
      {
        path: 'courseDetail',
        component: () => import('@/modules/resource/courseDetail')
      },
      {
        path: 'courseTalk',
        component: () => import('@/modules/resource/courseTalk')
      }
    ]
  },
  // 直播课堂
  {
    path: '/liveClass',
    component: Layout,
    redirect: '/liveClass/index',
    name: 'liveClass',
    meta: {
      title: '直播课堂'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/liveClass/index')
      },
      {
        path: 'detail',
        component: () => import('@/modules/liveClass/detail')
      },
      {
        path: 'list',
        component: () => import('@/modules/liveClass/list')
      },
      {
        path: 'courseDetail',
        component: () => import('@/modules/liveClass/courseDetail')
      },
      {
        path: 'courseTalk',
        component: () => import('@/modules/liveClass/courseTalk')
      }
    ]
  },
  // 校园文化
  {
    path: '/campus',
    component: Layout,
    redirect: '/campus/index',
    name: 'campus',
    meta: {
      title: '校园文化'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/campus/index')
      },
      {
        path: 'detail',
        component: () => import('@/modules/campus/detail')
      },
      {
        path: 'works',
        component: () => import('@/modules/campus/works')
      },
      {
        path: 'detailSchool',
        component: () => import('@/modules/campus/detailSchool')
      }
    ]
  },
  // 研学中心
  {
    path: '/study',
    component: Layout,
    redirect: '/study/index',
    name: 'study',
    meta: {
      title: '研学中心'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/study/index')
      },
      {
        path: 'detail',
        component: () => import('@/modules/study/detail')
      }
    ]
  },
  // 购物车
  {
    path: '/shoppingCart',
    component: Layout,
    redirect: '/shoppingCart/index',
    name: 'shoppingCart',
    meta: {
      title: '购物车'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/shoppingCart/index')
      },
      {
        path: 'order',
        component: () => import('@/modules/shoppingCart/order')
      },
      {
        path: 'payOrder',
        name: 'shoppingPayOrder',
        component: () => import('@/modules/shoppingCart/payOrder')
      },
      {
        path: 'gift',
        name: 'shoppingGift',
        component: () => import('@/modules/shoppingCart/gift')
      },
      {
        path: 'payStatus',
        name: 'shoppingPayStatus',
        component: () => import('@/modules/shoppingCart/payStatus')
      }
    ]
  },
  // 培训购物车
  {
    path: '/trainShoppingCart',
    component: Layout,
    redirect: '/trainShoppingCart/index',
    name: 'trainShoppingCart',
    meta: {
      title: '培训购物车'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/trainShoppingCart/index')
      },
      {
        path: 'order',
        component: () => import('@/modules/trainShoppingCart/order')
      },
      {
        path: 'payOrder',
        name: 'trainShoppingPayOrder',
        component: () => import('@/modules/trainShoppingCart/payOrder')
      },
      {
        path: 'gift',
        name: 'trainShoppingGift',
        component: () => import('@/modules/trainShoppingCart/gift')
      },
      {
        path: 'payStatus',
        name: 'trainShoppingPayStatus',
        component: () => import('@/modules/trainShoppingCart/payStatus')
      }
    ]
  },
  {
    path: '/education',
    component: Layout,
    redirect: '/education/index',
    name: 'education',
    meta: {
      title: '学历教育'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/education/index')
      }
    ]
  },
  {
    path: '/train',
    component: Layout,
    redirect: '/train/index',
    name: 'train',
    meta: {
      title: '省校报名'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/train/index')
      },
      {
        path: 'detail',
        component: () => import('@/modules/train/detail')
      },
      {
        path: 'course',
        component: () => import('@/modules/train/course')
      },
      {
        path: 'courseDetail',
        component: () => import('@/modules/train/courseDetail')
      }
    ]
  },
  {
    path: '/old',
    component: Layout,
    redirect: '/old/index',
    name: 'old',
    meta: {
      title: '省老年教育协会'
    },
    hidden: false,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/old/index')
      },
      {
        path: 'detail',
        component: () => import('@/modules/old/detail')
      }
    ]
  },
  {
    path: '/personal',
    component: Layout,
    redirect: '/personal/train',
    name: 'personal',
    meta: {
      title: '个人中心'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'train',
        component: () => import('@/modules/personal/train')
      },
      {
        path: 'index',
        component: () => import('@/modules/personal/index')
      },
      {
        path: 'correct',
        component: () => import('@/modules/personal/correct')
      },
      {
        path: 'correctDetail',
        component: () => import('@/modules/personal/correctDetail')
      }
    ]
  },
  {
    path: '/task',
    component: Layout,
    redirect: '/task/detail',
    name: 'task',
    meta: {
      title: '作业详情'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'detail',
        component: () => import('@/modules/task/detail')
      }
    ]
  },
  {
    path: '/feedback',
    component: Layout,
    redirect: '/feedback/index;',
    name: 'feedback',
    meta: {
      title: '意见反馈'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/feedback/index')
      }
    ]
  },
  {
    path: '/exam',
    component: Layout,
    redirect: '/exam/paper;',
    name: 'exam',
    meta: {
      title: '考试试卷'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'paper',
        component: () => import('@/modules/exam/paper')
      },
      {
        path: 'result',
        component: () => import('@/modules/exam/result')
      },
      {
        path: 'review',
        component: () => import('@/modules/exam/review')
      }
    ]
  },
  {
    path: '/search',
    component: Layout,
    redirect: '/search/index;',
    name: 'search',
    meta: {
      title: '搜索'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'index',
        component: () => import('@/modules/search/index')
      }
    ]
  },
   {
    path: '/authorize',
    name: 'authorize',
    hidden: true,
    meta: {
      title: '登录'
    },
   component: () => import('@/modules/auth/index')
   },
  {
    path: '/welcome',
    component: Layout,
    redirect: '/welcome/home;',
    name: 'welcome',
    meta: {
      title: '首页'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'home',
        component: () => import('@/modules/home/index')
      }
    ]
  },{
    path: '/learnCentre',
    name: 'learnCentre',
    hidden: true,
    meta: {
      title: '选择学习中心'
    },
    component: () => import('@/modules/auth/learnCentre')
  },{
    path: '/setFlag',
    name: 'setFlag',
    hidden: true,
    meta: {
      title: '设置flag'
    },
    component: () => import('@/modules/auth/setFlag')
  },{
    path: '/goLogin',
    name: 'goLogin',
    hidden: true,
    meta: {
      title: '去登录'
    },
    component: () => import('@/modules/auth/goLogin')
  },{
    path: '/loginRedirect/home',
    name: 'loginRedirect',
    meta: {
      title: '首页'
    },
    hidden: true,
    component: () => import('@/modules/auth/redirect')

  },
  {
    path: '/lndxCourse',
    component: Layout,
    redirect: '/lndxCourse/list',
    name: 'lndxCourse',
    meta: {
      title: '国家老年大学课程专区'
    },
    hidden: true,
    childs: false,
    children: [
      {
        path: 'list',
        component: () => import('@/modules/lndxCourse/list')
      }
    ]
  },
]

const router = new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: dynamicRouters
})

router.beforeEach((to, form, next) => {
  if (util.getParams('code')) {
    // 登录
    console.log('微信登录')
    setStorgeItem('wxcode', util.getParams('code'))
    window.location.href = window.wtConst.wxRedirectUri
  } else {
    next()
  }
})

export default router
